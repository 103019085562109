'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.MyScrollReveal = void 0;
var $ = require("jquery");
var scrollreveal_1 = require("scrollreveal");
var MyScrollReveal = /** @class */ (function () {
    // private ext: string = 'jpg';
    function MyScrollReveal(
    // private ajaxing: boolean = false,
    ) {
        var self = this;
        var elms = $('.bulletReveal');
        for (var i = 0; i < elms.length; i++) {
        }
        scrollreveal_1.default().reveal('.bulletReveal[data-reveal="fadeIn"]', {
            delay: 500,
            duration: 500,
            easing: 'ease-in',
        });
        scrollreveal_1.default().reveal('.bulletReveal[data-reveal="LtoR"]', {
            delay: 500,
            duration: 500,
            easing: 'ease-in',
            distance: '50px',
            origin: 'left',
        });
        scrollreveal_1.default().reveal('.bulletReveal[data-reveal="RtoL"]', {
            delay: 500,
            duration: 500,
            easing: 'ease-in',
            distance: '50px',
            origin: 'right',
        });
        scrollreveal_1.default().reveal('.bulletReveal[data-reveal="BtoT"]', {
            delay: 500,
            duration: 500,
            easing: 'ease-in',
            distance: '50px',
            origin: 'bottom',
        });
    }
    return MyScrollReveal;
}());
exports.MyScrollReveal = MyScrollReveal;
exports.default = MyScrollReveal;
